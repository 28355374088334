import React from 'react';

import Layout from '../components/Layout';
import logo from '../assets/images/cellerMinifundi.png';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

const IndexPage = () => (
  <Layout>
    <article id="page-404">
      <header>
        <img className="logo" src={logo} alt="Celler del Minifundi" />
        <h3>
          <FormattedMessage id="notFound" />
        </h3>
      </header>
    </article>
  </Layout>
);

export default injectIntl(IndexPage);
